import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData) {
  return request.post(constant.serverUrl + "/base/parkingRecord/pageList", formData);
}

function inOutPageList(formData) {
  return request.post(constant.serverUrl + "/base/parkingRecord/inOutPageList", formData);
}

function carTypeList() {
  return request.post(constant.serverUrl + "/base/parkingRecord/carTypeList");
}



export default {
  pageList,carTypeList,inOutPageList
}